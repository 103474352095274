import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import {
  areDatesSame,
  getFormattedDateInTz2,
  getFormattedTimeOnyInTz,
  openInNewTab,
} from '../../utility/commonUtil';
import DateIcon from '../icons/DateIcon';
import { renderRowStyle } from '../../utility/commonUtil';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';
import './schedule.scss';
import TranshcanIcon from '../icons/TrashcanIcon';
import {
  customSelectStyle3,
  customSelectStyleSchedule,
} from '../reselect/selectUtil';
import { getValue } from '../../utility/commonUtil';
import SearchIcon from '../icons/SearchIcon';
import Select from 'react-select';
import ModalX from '../modal/ModalX';
import AddScheduleForm from './AddScheduleForm';
import AddLectureForm from './AddLectureForm';
import ButtonWithLoader from '../button/ButtonWithLoader';
import DatePicker from 'react-datepicker';
import { alertAction } from '../../redux/alert/alertAction';
import EditIcon from '../icons/EditIcon';
import CheckTickIcon from '../icons/CheckTickIcon';
import AddFileIcon from '../icons/AddFileIcon';
import DialogueWithLoader from '../dialogue/DialogueWithLoader';
import PlusIcon from '../icons/PlusIcon';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../button/BackButton';
import Loader from '../loader/Loader';

export default function ScheduleView() {
  const [searchText, setSearchText] = useState('');
  const user = useSelector((state) => state.auth.user);
  const [filter1, setFilter1] = useState('all');
  const [filter2, setFilter2] = useState('all');
  const [selectedDate, setSelectedDate] = useState(null);
  const confId = useParams().confId;

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filteredList, setFilteredList] = useState([]);
  const [halls, setHalls] = useState([]);
  const [conference, setConference] = useState('');
  const [sessions, setSessions] = useState([]);

  const [splicedList, setSplicedList] = useState([]);

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowId, setRowId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };

  const getConferences = async () => {
    try {
      const response = await api.get(`common/conferences/${confId}`);

      setConference(response.data.data.conference);
    } catch (error) {}
  };

  const getHalls = async (confId) => {
    const url = `venues/conference/${confId}`;
    try {
      const response = await api.get(url);

      if (response) {
        setHalls([{ label: 'All', value: 'all' }, ...response.data.data.halls]);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const getSessions = async () => {
    const url = `session/conferences/users/${user?._id}?conferenceId=${conference?.value}`;
    try {
      setIsLoading(true);
      const response = await api.get(url);

      if (response) {
        setConference(response.data.data.conference);
        setFilter1(response.data.data.conference.value);
        setSessions(response.data.data.sessions);
        getHalls(response.data.data.conference.value);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    getConferences(user?._id);
    getSessions();
  }, [user._id]);

  useEffect(() => {
    getSessions();
  }, [filter1]);

  useEffect(() => {
    let venueFilter = sessions;

    if (filter2 === 'all') {
      venueFilter = sessions;
    } else {
      venueFilter = sessions?.filter((session) => session.venueId === filter2);
    }

    const filtered = venueFilter.filter((session) => {
      const matchesTitle = session.title
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const matchesSpeakers = session.speakers.some((speaker) =>
        `${speaker.speaker.firstName} ${speaker.speaker.lastName}`
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      const matchesConvenors = session.convenors.some((convenor) =>
        `${convenor.speaker.firstName} ${convenor.speaker.lastName}`
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      const matchesEpisodes = session.episodes?.some((episode) => {
        const episodeTitleMatch = episode.title
          ?.toLowerCase()
          .includes(searchText.toLowerCase());

        const episodeSpeakersMatch = episode.speakers?.some((speaker) =>
          `${speaker.speaker.firstName} ${speaker.speaker.lastName}`
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );

        const episodeConvenorsMatch = episode.convenors?.some((convenor) =>
          `${convenor.speaker.firstName} ${convenor.speaker.lastName}`
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );

        return (
          episodeTitleMatch || episodeSpeakersMatch || episodeConvenorsMatch
        );
      });
      return (
        matchesTitle || matchesSpeakers || matchesConvenors || matchesEpisodes
      );
    });

    // Define the start and end of the date range

    const filterDate = selectedDate ? new Date(selectedDate) : null; // selectedDate is your date input
    let startOfDay, endOfDay;

    if (filterDate) {
      startOfDay = new Date(filterDate);
      startOfDay.setHours(0, 0, 0, 0); // Set to start of the day (12:00 AM)

      endOfDay = new Date(filterDate);
      endOfDay.setHours(23, 59, 59, 999); // Set to end of the day (11:59:59 PM)
    }

    const finalFilterResult = filterDate
      ? filtered.filter(
          (session) =>
            new Date(session.startDate) >= startOfDay &&
            new Date(session.startDate) <= endOfDay
        )
      : filtered;

    setFilteredList(finalFilterResult);
    setTotalRecords(finalFilterResult?.length);
  }, [sessions, filter2, searchText, selectedDate]);

  const loadPaginationData = (data) => {
    const { currentPage, totalRecords, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const splicedData = filteredList.slice(0, pageLimit);

      setSplicedList(splicedData);
    } else {
      const splicedData = filteredList.slice(
        indexOfFirstElement,
        indexOflastElement
      );

      setSplicedList(splicedData);
    }
  };

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [filteredList]);

  return (
    <div className="cd-container">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="myconfs-header">
            <h1>Schedule</h1>
          </div>
          {conference && (
            <h3 className="mb-16 mt-16 color-primary">{`CONFERENCE: ${conference?.label} `}</h3>
          )}

          <div className="scheduleView-sort mb-16 mt-32">
            <div className="form-type-session">
              <div style={{ position: 'relative' }}>
                <input
                  type="text"
                  id="myConfsSearchText"
                  placeholder="Search Schedules"
                  name="searchText"
                  value={searchText}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchText(e.target.value);
                  }}
                  autoComplete="off"
                />
                <i
                  className={
                    searchText?.length > 0
                      ? 'display-none'
                      : 'conf-search-input-icon'
                  }
                >
                  <SearchIcon width="2.4rem" height="3.4rem" />
                </i>
              </div>
            </div>
            <div style={{ minHeight: '58px' }}>
              <Select
                value={getValue(halls, filter2, false)}
                options={halls}
                name="filter1"
                styles={customSelectStyleSchedule}
                onChange={(option) => {
                  setFilter2(option.value);
                }}
                placeholder="Select Hall"
              />
            </div>
            <div className="form-type-session">
              <div>
                <DatePicker
                  selected={selectedDate}
                  onChange={(val) => setSelectedDate(val)}
                  isClearable
                  placeholderText="Date"
                />
              </div>
            </div>
          </div>

          <div className="schedule-scrollbar-grid-table-wrap">
            <ul className="schedule-grid-table-2-500 schedule-gridtable">
              <li>Time</li>
              <li>Sessions</li>

              {splicedList.length > 0 &&
                splicedList?.map((item, indx) => {
                  let isRowHovered =
                    isHover && rowId.toString() === item?._id.toString();
                  return (
                    <Fragment key={item?._id}>
                      <li
                        onMouseEnter={() => handleMouseEnter(item?._id)}
                        onMouseLeave={() => handleMouseLeave(item?._id)}
                        style={renderRowStyle(indx, item?._id, isHover, rowId)}
                      >
                        <div className="flex-vc">
                          <div>
                            <DateIcon className="icon-xxs mr-12" />
                          </div>
                          <div>
                            <p>
                              {item?.startDate && item?.endDate
                                ? areDatesSame(
                                    item?.startDate,
                                    item?.endDate,
                                    conference?.timezone
                                  )
                                  ? `${getFormattedDateInTz2(
                                      item?.startDate,
                                      conference.timezone
                                    )}`
                                  : `${getFormattedDateInTz2(
                                      item?.startDate,
                                      conference?.timezone
                                    )} ${getFormattedTimeOnyInTz(
                                      item?.startDate,
                                      conference?.timezone
                                    )} - ${getFormattedDateInTz2(
                                      item?.endDate,
                                      conference?.timezone
                                    )} ${getFormattedTimeOnyInTz(
                                      item?.endDate,
                                      conference?.timezone
                                    )}`
                                : ''}
                            </p>
                            {item?.startDate &&
                              item?.endDate &&
                              areDatesSame(
                                item?.startDate,
                                item?.endDate,
                                conference?.timezone
                              ) && (
                                <p
                                  style={{ whiteSpace: 'nowrap' }}
                                  className="caption-2-regular-gray3"
                                >
                                  {`${getFormattedTimeOnyInTz(
                                    item?.startDate,
                                    conference?.timezone
                                  )} - ${getFormattedTimeOnyInTz(
                                    item?.endDate,
                                    conference?.timezone
                                  )}`}
                                </p>
                              )}
                          </div>
                        </div>
                      </li>
                      <li
                        onMouseEnter={() => handleMouseEnter(item?._id)}
                        onMouseLeave={() => handleMouseLeave(item?._id)}
                        style={renderRowStyle(indx, item?._id, isHover, rowId)}
                      >
                        <div className="flex-sb">
                          <div>
                            <p
                              style={{
                                wordBreak: 'break-word',
                                flex: 1,
                              }}
                              className="avenir-heavy-18"
                            >
                              {item?.title}
                            </p>

                            <div>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-1-regular-gray3 mr-4"
                              >
                                Venue:
                              </span>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-2-regular-gray3"
                              >
                                {item?.venue}
                              </span>
                            </div>
                            <div>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-1-regular-gray3 mr-4"
                              >
                                Convenors:
                              </span>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-2-regular-gray3"
                              >
                                {item?.convenors
                                  ?.map(
                                    (item) =>
                                      `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
                                  )
                                  .join(', ')}
                              </span>
                            </div>

                            <div>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-1-regular-gray3 mr-4"
                              >
                                Speakers:
                              </span>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-2-regular-gray3"
                              >
                                {item?.speakers
                                  ?.map(
                                    (item) =>
                                      `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
                                  )
                                  .join(', ')}
                              </span>
                            </div>
                            {item?.resources?.length > 0 && (
                              <div className="flex-vc">
                                <div>
                                  <span
                                    style={{ whiteSpace: 'nowrap' }}
                                    className="caption-1-regular-gray3 mr-4"
                                  >
                                    Files:
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '8px',
                                  }}
                                >
                                  {item?.resources?.map((resource, index) => (
                                    <p
                                      key={index}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        maxWidth: '150px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        color: '#08415c',
                                      }}
                                      className="caption-2-regular-gray3"
                                      title={resource?.fileNameWithExtension}
                                    >
                                      <AddFileIcon className="icon-xs mr-4" />
                                      <span
                                        onClick={() =>
                                          openInNewTab(resource?.Location)
                                        }
                                      >
                                        {resource?.fileNameWithExtension
                                          .length > 10
                                          ? `${resource?.fileNameWithExtension.substring(
                                              0,
                                              10
                                            )}...`
                                          : resource?.fileNameWithExtension}
                                      </span>
                                    </p>
                                  ))}
                                </div>
                              </div>
                            )}
                            {item?.powerPointFile?.length > 0 && (
                              <div className="flex-vc">
                                <div>
                                  <span
                                    style={{ whiteSpace: 'nowrap' }}
                                    className="caption-1-regular-gray3 mr-4"
                                  >
                                    PowerPoint:
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '8px',
                                  }}
                                >
                                  {item?.powerPointFile?.map(
                                    (resource, index) => (
                                      <p
                                        key={index}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          maxWidth: '300px',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          color: '#08415c',
                                        }}
                                        className="caption-2-regular-gray3"
                                        title={resource?.fileNameWithExtension}
                                      >
                                        <AddFileIcon className="icon-xs mr-4" />
                                        <span
                                          onClick={() =>
                                            openInNewTab(resource?.Location)
                                          }
                                        >
                                          {resource?.fileNameWithExtension
                                            .length > 45
                                            ? `${resource?.fileNameWithExtension.substring(
                                                0,
                                                45
                                              )}...`
                                            : resource?.fileNameWithExtension}
                                        </span>
                                      </p>
                                    )
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div>
                          {item?.episodes?.map((episode, index) => {
                            const isRowHovered =
                              isHover &&
                              rowId.toString() === episode.session.toString();

                            return (
                              <>
                                <div className="flex-sb ml-32 mt-12">
                                  <div>
                                    <p
                                      style={{
                                        flex: 1,
                                        wordBreak: 'break-word',
                                      }}
                                      className="avenir-heavy-18"
                                    >
                                      {`Lecture ${index + 1}: ${
                                        episode?.title
                                      }`}
                                    </p>

                                    <div>
                                      <DateIcon className="icon-xxs mr-12" />
                                      <span
                                        style={{ whiteSpace: 'nowrap' }}
                                        className="caption-2-regular-gray3"
                                      >
                                        {`${getFormattedTimeOnyInTz(
                                          episode?.startDate,
                                          conference?.timezone
                                        )} - ${getFormattedTimeOnyInTz(
                                          episode?.endDate,
                                          conference?.timezone
                                        )}`}
                                      </span>
                                    </div>

                                    <div>
                                      <span
                                        style={{ whiteSpace: 'nowrap' }}
                                        className="caption-1-regular-gray3 mr-4"
                                      >
                                        Speakers:
                                      </span>
                                      <span
                                        style={{ whiteSpace: 'nowrap' }}
                                        className="caption-2-regular-gray3"
                                      >
                                        {item?.speakers
                                          ?.map(
                                            (item) =>
                                              `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
                                          )
                                          .join(', ')}
                                      </span>
                                    </div>
                                    {episode?.resources?.length > 0 && (
                                      <div className="flex-vc">
                                        <div>
                                          <span
                                            style={{ whiteSpace: 'nowrap' }}
                                            className="caption-1-regular-gray3 mr-4"
                                          >
                                            Files:
                                          </span>
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: '8px',
                                          }}
                                        >
                                          {episode?.resources?.map(
                                            (resource, index) => (
                                              <p
                                                key={index}
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  maxWidth: '150px',
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  color: '#08415c',
                                                }}
                                                className="caption-2-regular-gray3"
                                                title={
                                                  resource?.fileNameWithExtension
                                                }
                                              >
                                                <AddFileIcon className="icon-xs mr-4" />
                                                <span
                                                  onClick={() =>
                                                    openInNewTab(
                                                      resource?.Location
                                                    )
                                                  }
                                                >
                                                  {resource
                                                    ?.fileNameWithExtension
                                                    .length > 10
                                                    ? `${resource?.fileNameWithExtension.substring(
                                                        0,
                                                        10
                                                      )}...`
                                                    : resource?.fileNameWithExtension}
                                                </span>
                                              </p>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    {episode?.powerPointFile?.length > 0 && (
                                      <div className="flex-vc">
                                        <div>
                                          <span
                                            style={{ whiteSpace: 'nowrap' }}
                                            className="caption-1-regular-gray3 mr-4"
                                          >
                                            PowerPoint:
                                          </span>
                                        </div>

                                        <div
                                          style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            gap: '8px',
                                          }}
                                        >
                                          {episode?.powerPointFile?.map(
                                            (resource, index) => (
                                              <p
                                                key={index}
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  maxWidth: '350px',
                                                  whiteSpace: 'nowrap',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  color: '#08415c',
                                                }}
                                                className="caption-2-regular-gray3"
                                                title={
                                                  resource?.fileNameWithExtension
                                                }
                                              >
                                                <AddFileIcon className="icon-xs mr-4" />
                                                <span
                                                  onClick={() =>
                                                    openInNewTab(
                                                      resource?.Location
                                                    )
                                                  }
                                                >
                                                  {resource
                                                    ?.fileNameWithExtension
                                                    .length > 50
                                                    ? `${resource?.fileNameWithExtension.substring(
                                                        0,
                                                        45
                                                      )}...`
                                                    : resource?.fileNameWithExtension}
                                                </span>
                                              </p>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </li>
                    </Fragment>
                  );
                })}
            </ul>
          </div>

          <div className="flex-vc-sb mt-8">
            <div>
              <CustomPagination
                currentPageNumber={activePage}
                setCurrentPage={setActivePage}
                totalRecords={totalRecords}
                pageLimit={pageLimit}
                pageNeighbours={1}
                onPageChanged={(data) => loadPaginationData(data)}
              />
            </div>
            <div>
              <PageLimitSelect
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                activePage={activePage}
                totalRecords={totalRecords}
                loadPaginationData={loadPaginationData}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
