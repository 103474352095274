import { Fragment } from 'react';

import {
  areDatesSame,
  getFormattedDateInTz2,
  getFormattedTimeOnyInTz,
  openInNewTab,
} from '../../utility/commonUtil';
import DateIcon from '../icons/DateIcon';

import './schedule.scss';
import AddFileIcon from '../icons/AddFileIcon';

export default function PrintSchedule({ sessions, conference }) {
  return (
    <>
      <div className="schedule-print-scrollbar-grid-table-wrap">
        <ul className="schedule-grid-table-2-500 schedule-gridtable">
          <li>Time</li>
          <li>Sessions</li>

          {sessions.length > 0 &&
            sessions?.map((item, indx) => {
              return (
                <Fragment key={item?._id}>
                  <li>
                    <div className="flex-vc">
                      <div>
                        <DateIcon className="icon-xxs mr-12" />
                      </div>
                      <div>
                        <p>
                          {item?.startDate && item?.endDate
                            ? areDatesSame(
                                item?.startDate,
                                item?.endDate,
                                conference?.timezone
                              )
                              ? `${getFormattedDateInTz2(
                                  item?.startDate,
                                  conference.timezone
                                )}`
                              : `${getFormattedDateInTz2(
                                  item?.startDate,
                                  conference?.timezone
                                )} ${getFormattedTimeOnyInTz(
                                  item?.startDate,
                                  conference?.timezone
                                )} - ${getFormattedDateInTz2(
                                  item?.endDate,
                                  conference?.timezone
                                )} ${getFormattedTimeOnyInTz(
                                  item?.endDate,
                                  conference?.timezone
                                )}`
                            : ''}
                        </p>
                        {item?.startDate &&
                          item?.endDate &&
                          areDatesSame(
                            item?.startDate,
                            item?.endDate,
                            conference?.timezone
                          ) && (
                            <p
                              style={{ whiteSpace: 'nowrap' }}
                              className="caption-2-regular-gray3"
                            >
                              {`${getFormattedTimeOnyInTz(
                                item?.startDate,
                                conference?.timezone
                              )} - ${getFormattedTimeOnyInTz(
                                item?.endDate,
                                conference?.timezone
                              )}`}
                            </p>
                          )}
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="flex-sb">
                      <div>
                        <p
                          style={{
                            wordBreak: 'break-word',
                            flex: 1,
                          }}
                          className="avenir-heavy-18"
                        >
                          {item?.title}
                        </p>

                        <div>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-1-regular-gray3 mr-4"
                          >
                            Venue:
                          </span>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-2-regular-gray3"
                          >
                            {item?.venue}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-1-regular-gray3 mr-4"
                          >
                            Convenors:
                          </span>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-2-regular-gray3"
                          >
                            {item?.convenors
                              ?.map(
                                (item) =>
                                  `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
                              )
                              .join(', ')}
                          </span>
                        </div>

                        <div>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-1-regular-gray3 mr-4"
                          >
                            Speakers:
                          </span>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-2-regular-gray3"
                          >
                            {item?.speakers
                              ?.map(
                                (item) =>
                                  `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
                              )
                              .join(', ')}
                          </span>
                        </div>
                        {item?.resources?.length > 0 && (
                          <div className="flex-vc">
                            <div>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-1-regular-gray3 mr-4"
                              >
                                Files:
                              </span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '8px',
                              }}
                            >
                              {item?.resources?.map((resource, index) => (
                                <p
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxWidth: '150px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: '#08415c',
                                  }}
                                  className="caption-2-regular-gray3"
                                  title={resource?.fileNameWithExtension}
                                >
                                  <AddFileIcon className="icon-xs mr-4" />
                                  <span
                                    onClick={() =>
                                      openInNewTab(resource?.Location)
                                    }
                                  >
                                    {resource?.fileNameWithExtension.length > 10
                                      ? `${resource?.fileNameWithExtension.substring(
                                          0,
                                          10
                                        )}...`
                                      : resource?.fileNameWithExtension}
                                  </span>
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                        {item?.powerPointFile?.length > 0 && (
                          <div className="flex-vc">
                            <div>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-1-regular-gray3 mr-4"
                              >
                                PowerPoint:
                              </span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '8px',
                              }}
                            >
                              {item?.powerPointFile?.map((resource, index) => (
                                <p
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxWidth: '300px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: '#08415c',
                                  }}
                                  className="caption-2-regular-gray3"
                                  title={resource?.fileNameWithExtension}
                                >
                                  <AddFileIcon className="icon-xs mr-4" />
                                  <span
                                    onClick={() =>
                                      openInNewTab(resource?.Location)
                                    }
                                  >
                                    {resource?.fileNameWithExtension.length > 45
                                      ? `${resource?.fileNameWithExtension.substring(
                                          0,
                                          45
                                        )}...`
                                      : resource?.fileNameWithExtension}
                                  </span>
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div>
                      {item?.episodes?.map((episode, index) => {
                        return (
                          <>
                            <div className="flex-sb ml-32 mt-12 lecture-block">
                              <div>
                                <p
                                  style={{
                                    flex: 1,
                                    wordBreak: 'break-word',
                                  }}
                                  className="avenir-heavy-18 lecture-title"
                                >
                                  {`Lecture ${index + 1}: ${episode?.title}`}
                                </p>

                                <div>
                                  <DateIcon className="icon-xxs mr-12" />
                                  <span
                                    style={{ whiteSpace: 'nowrap' }}
                                    className="caption-2-regular-gray3"
                                  >
                                    {`${getFormattedTimeOnyInTz(
                                      episode?.startDate,
                                      conference.timezone
                                    )} - ${getFormattedTimeOnyInTz(
                                      episode?.endDate,
                                      conference.timezone
                                    )}`}
                                  </span>
                                </div>

                                <div>
                                  <span
                                    style={{ whiteSpace: 'nowrap' }}
                                    className="caption-1-regular-gray3 mr-4"
                                  >
                                    Speakers:
                                  </span>
                                  <span
                                    style={{ whiteSpace: 'nowrap' }}
                                    className="caption-2-regular-gray3"
                                  >
                                    {item?.speakers
                                      ?.map(
                                        (item) =>
                                          `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
                                      )
                                      .join(', ')}
                                  </span>
                                </div>
                                {episode?.resources?.length > 0 && (
                                  <div className="flex-vc">
                                    <div>
                                      <span
                                        style={{ whiteSpace: 'nowrap' }}
                                        className="caption-1-regular-gray3 mr-4"
                                      >
                                        Files:
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                      }}
                                    >
                                      {episode?.resources?.map(
                                        (resource, index) => (
                                          <p
                                            key={index}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              maxWidth: '150px',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              color: '#08415c',
                                            }}
                                            className="caption-2-regular-gray3"
                                            title={
                                              resource?.fileNameWithExtension
                                            }
                                          >
                                            <AddFileIcon className="icon-xs mr-4" />
                                            <span
                                              onClick={() =>
                                                openInNewTab(resource?.Location)
                                              }
                                            >
                                              {resource?.fileNameWithExtension
                                                .length > 10
                                                ? `${resource?.fileNameWithExtension.substring(
                                                    0,
                                                    10
                                                  )}...`
                                                : resource?.fileNameWithExtension}
                                            </span>
                                          </p>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                                {episode?.powerPointFile?.length > 0 && (
                                  <div className="flex-vc">
                                    <div>
                                      <span
                                        style={{ whiteSpace: 'nowrap' }}
                                        className="caption-1-regular-gray3 mr-4"
                                      >
                                        PowerPoint:
                                      </span>
                                    </div>

                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                      }}
                                    >
                                      {episode?.powerPointFile?.map(
                                        (resource, index) => (
                                          <p
                                            key={index}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              maxWidth: '350px',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              color: '#08415c',
                                            }}
                                            className="caption-2-regular-gray3"
                                            title={
                                              resource?.fileNameWithExtension
                                            }
                                          >
                                            <AddFileIcon className="icon-xs mr-4" />
                                            <span
                                              onClick={() =>
                                                openInNewTab(resource?.Location)
                                              }
                                            >
                                              {resource?.fileNameWithExtension
                                                .length > 50
                                                ? `${resource?.fileNameWithExtension.substring(
                                                    0,
                                                    45
                                                  )}...`
                                                : resource?.fileNameWithExtension}
                                            </span>
                                          </p>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </li>
                </Fragment>
              );
            })}
        </ul>
      </div>
    </>
  );
}
