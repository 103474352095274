import { useEffect, useState, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utility/api';
import {
  areDatesSame,
  getFormattedDateInTz2,
  getFormattedTimeOnyInTz,
  openInNewTab,
} from '../../utility/commonUtil';
import DateIcon from '../icons/DateIcon';
import { renderRowStyle } from '../../utility/commonUtil';
import CustomPagination from '../pagination/CustomPagination';
import PageLimitSelect from '../pagination/PageLimitSelect';
import './schedule.scss';
import TranshcanIcon from '../icons/TrashcanIcon';
import {
  customSelectStyle3,
  customSelectStyleSchedule,
} from '../reselect/selectUtil';
import { getValue } from '../../utility/commonUtil';
import SearchIcon from '../icons/SearchIcon';
import Select from 'react-select';
import ModalX from '../modal/ModalX';
import AddScheduleForm from './AddScheduleForm';
import AddLectureForm from './AddLectureForm';
import ButtonWithLoader from '../button/ButtonWithLoader';
import DatePicker from 'react-datepicker';
import { alertAction } from '../../redux/alert/alertAction';
import EditIcon from '../icons/EditIcon';
import CheckTickIcon from '../icons/CheckTickIcon';
import AddFileIcon from '../icons/AddFileIcon';
import DialogueWithLoader from '../dialogue/DialogueWithLoader';
import PlusIcon from '../icons/PlusIcon';
import PrinterIcon from '../icons/PrinterIcon';
import DownloadIcon from '../icons/DownloadIcon';
import PrintSchedule from './PrintSchedule';
import { useReactToPrint } from 'react-to-print';
import html2pdf from 'html2pdf.js';

export default function Schedule() {
  const [searchText, setSearchText] = useState('');
  const user = useSelector((state) => state.auth.user);
  const [filter1, setFilter1] = useState('all');
  const [filter2, setFilter2] = useState('all');
  const [selectedDate, setSelectedDate] = useState(null);

  // for pagination
  const [activePage, setActivePage] = useState({ currentPage: 1 });
  const [pageLimit, setPageLimit] = useState(5);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filteredList, setFilteredList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLectureForm, setShowLectureForm] = useState(false);
  const [conferences, setConferences] = useState([]);
  const [halls, setHalls] = useState([]);
  const [conference, setConference] = useState('');
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState('');
  const [rowData, setRowData] = useState('');
  const [splicedList, setSplicedList] = useState([]);
  const [sessionDeleteDialogue, setSessionDeleteDialogue] = useState(false);
  const [isDialogueLoading, setIsDialogueLoading] = useState(false);
  const [lectureDeleteDialogue, setLectureDeleteDialogue] = useState(false);

  // to highlight row on hover
  const [isHover, setIsHover] = useState(false);
  const [rowId, setRowId] = useState(null);

  const dispatch = useDispatch();

  const handleMouseEnter = (rowId) => {
    setRowId(rowId);
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setRowId(null);
    setIsHover(false);
  };
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Print The Schedule',
  });

  const generateAndSavePDF = async () => {
    const element = componentRef.current;
    // Simulate asynchronous work, e.g., fetching data or generating PDF

    const options = {
      margin: 1,
      filename: 'schedule.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2, // Increase scale for better resolution
        scrollX: 0, // Prevent horizontal scroll clipping
        scrollY: 0, // Prevent vertical scroll clipping
        useCORS: true, // Enable CORS for external assets like images
      },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
    };

    await html2pdf().from(element).set(options).save();
  };

  const printData = async () => {
    const element = componentRef.current;
    // Simulate asynchronous work, e.g., fetching data or generating PDF

    const options = {
      margin: 1,
      filename: 'schedule.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2, // Increase scale for better resolution
        scrollX: 0, // Prevent horizontal scroll clipping
        scrollY: 0, // Prevent vertical scroll clipping
        useCORS: true, // Enable CORS for external assets like images
      },
      jsPDF: { unit: 'mm', format: 'a3', orientation: 'portrait' },
    };

    // await html2pdf().from(element).set(options).save();
    html2pdf()
      .from(element)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(function (pdf) {
        pdf.autoPrint();
        window.open(pdf.output('bloburl'), '_blank');
      });
  };

  const getConferences = async (userId) => {
    try {
      const response = await api.get(`session/allConferences/users/${userId}`);

      setConferences(response.data.data.conferences);
    } catch (error) {}
  };

  const getHalls = async (confId) => {
    const url = `venues/conference/${confId}`;
    try {
      const response = await api.get(url);

      if (response) {
        setHalls([{ label: 'All', value: 'all' }, ...response.data.data.halls]);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const deleteData = async (url) => {
    try {
      setIsDialogueLoading(true);
      const response = await api.delete(url);

      setSessions(response.data.data.sessions);
      setIsDialogueLoading(false);
      setSessionDeleteDialogue(false);
      setLectureDeleteDialogue(false);
      dispatch(alertAction('Session deleted successfully', 'success'));
      setRowData('');
    } catch (err) {
      setIsDialogueLoading(false);
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const getSessions = async () => {
    const url = `session/conferences/users/${user?._id}?conferenceId=${conference?.value}`;
    try {
      const response = await api.get(url);
      console.log(response.data.data.sessions);
      if (response) {
        setConference(response.data.data.conference);
        setFilter1(response.data.data.conference.value);
        setSessions(response.data.data.sessions);
        getHalls(response.data.data.conference.value);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  const changeScheduleStatus = async () => {
    const url = `session/conference/${conference?.value}`;
    try {
      let formData = {
        conferenceDetails: {
          status: !conference?.publishSchedule,
        },
      };
      const response = await api.patch(url, formData);

      if (response) {
        setConference(response.data.data.conference);
      }
    } catch (err) {
      dispatch(
        alertAction(
          err?.response?.data?.message || 'Something went wrong',
          'danger'
        )
      );
    }
  };

  useEffect(() => {
    getConferences(user?._id);
    getSessions();
  }, [user._id]);

  useEffect(() => {
    getSessions();
  }, [filter1]);

  useEffect(() => {
    let venueFilter = sessions;

    if (filter2 === 'all') {
      venueFilter = sessions;
    } else {
      venueFilter = sessions?.filter((session) => session.venueId === filter2);
    }

    const filtered = venueFilter.filter((session) => {
      const matchesTitle = session.title
        .toLowerCase()
        .includes(searchText.toLowerCase());
      const matchesSpeakers = session.speakers.some((speaker) =>
        `${speaker.speaker.firstName} ${speaker.speaker.lastName}`
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
      const matchesConvenors = session.convenors.some((convenor) =>
        `${convenor.speaker.firstName} ${convenor.speaker.lastName}`
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );

      const matchesEpisodes = session.episodes?.some((episode) => {
        const episodeTitleMatch = episode.title
          ?.toLowerCase()
          .includes(searchText.toLowerCase());

        const episodeSpeakersMatch = episode.speakers?.some((speaker) =>
          `${speaker.speaker.firstName} ${speaker.speaker.lastName}`
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );

        const episodeConvenorsMatch = episode.convenors?.some((convenor) =>
          `${convenor.speaker.firstName} ${convenor.speaker.lastName}`
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );

        return (
          episodeTitleMatch || episodeSpeakersMatch || episodeConvenorsMatch
        );
      });
      return (
        matchesTitle || matchesSpeakers || matchesConvenors || matchesEpisodes
      );
    });

    // Define the start and end of the date range

    const filterDate = selectedDate ? new Date(selectedDate) : null; // selectedDate is your date input
    let startOfDay, endOfDay;

    if (filterDate) {
      startOfDay = new Date(filterDate);
      startOfDay.setHours(0, 0, 0, 0); // Set to start of the day (12:00 AM)

      endOfDay = new Date(filterDate);
      endOfDay.setHours(23, 59, 59, 999); // Set to end of the day (11:59:59 PM)
    }

    const finalFilterResult = filterDate
      ? filtered.filter(
          (session) =>
            new Date(session.startDate) >= startOfDay &&
            new Date(session.startDate) <= endOfDay
        )
      : filtered;

    setFilteredList(finalFilterResult);
    setTotalRecords(finalFilterResult?.length);
  }, [sessions, filter2, searchText, selectedDate]);

  const loadPaginationData = (data) => {
    const { currentPage, totalRecords, pageLimit } = data;

    const indexOflastElement = currentPage * pageLimit;
    const indexOfFirstElement = indexOflastElement - pageLimit;

    if (totalRecords < indexOfFirstElement + 1) {
      setActivePage({ currentPage: 1 });
      const splicedData = filteredList.slice(0, pageLimit);

      setSplicedList(splicedData);
    } else {
      const splicedData = filteredList.slice(
        indexOfFirstElement,
        indexOflastElement
      );

      setSplicedList(splicedData);
    }
  };

  useEffect(() => {
    const dataforPagination = {
      currentPage: activePage?.currentPage,
      pageLimit: pageLimit,
      totalRecords,
    };
    loadPaginationData(dataforPagination);
  }, [filteredList]);
  return (
    <>
      <div className="myconfs-header mb-16">
        <h1>Schedules</h1>
      </div>
      <div className="mb-32">
        <h4 className="mb-24">Pick conference from below for it's schedule</h4>
        {conference && (
          <h3 className="mb-16">{`CONFERENCE: ${conference?.label} `}</h3>
        )}
        <Select
          value={getValue(conferences, filter1, false)}
          options={conferences}
          name="filter1"
          styles={customSelectStyle3}
          onChange={(option) => {
            setConference(option);
            setFilter1(option.value);
          }}
          placeholder="Select Conference"
        />
      </div>
      <div className="schedule-sort mb-16 mt-64">
        <div className="form-type-session">
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              id="myConfsSearchText"
              placeholder="Search Schedules"
              name="searchText"
              value={searchText}
              onChange={(e) => {
                e.preventDefault();
                setSearchText(e.target.value);
              }}
              autoComplete="off"
            />
            <i
              className={
                searchText?.length > 0
                  ? 'display-none'
                  : 'conf-search-input-icon'
              }
            >
              <SearchIcon width="2.4rem" height="3.4rem" />
            </i>
          </div>
        </div>
        <div style={{ minHeight: '58px' }}>
          <Select
            value={getValue(halls, filter2, false)}
            options={halls}
            name="filter1"
            styles={customSelectStyleSchedule}
            onChange={(option) => {
              setFilter2(option.value);
            }}
            placeholder="Select Hall"
          />
        </div>
        <div className="form-type-session">
          <div>
            <DatePicker
              selected={selectedDate}
              onChange={(val) => setSelectedDate(val)}
              isClearable
              placeholderText="Date"
            />
          </div>
        </div>

        <ButtonWithLoader
          // isSubmitting={previewLoading}
          text="+ Create Schedule"
          className="button button-green"
          fullWidth={true}
          // icon={<PlusIcon className="icon-xs mt-2" fill="#fff" />}
          handleClick={() => setShowModal(true)}
        />

        <div>
          <ButtonWithLoader
            // isSubmitting={previewLoading}
            text={
              conference?.publishSchedule
                ? 'Unpublish Schedule'
                : 'Publish Schedule'
            }
            className="button button-primary"
            fullWidth={true}
            handleClick={() => changeScheduleStatus()}
          />
        </div>
      </div>
      <div className="flex-sb">
        <div className=" flex-vc mb-4">
          {!conference?.publishSchedule ? (
            <>
              <i className="mr-4">
                <AddFileIcon className="icon-xs" />
              </i>
              <p className="caption-2-regular-gray3">
                To publish the schedule use publish schedule button on top right
                corner of the table.
              </p>
            </>
          ) : (
            <>
              <i className="mr-4">
                <CheckTickIcon className="icon-xs" />
              </i>
              <p className="caption-2-regular-gray3">
                Published. Any changes you make will be automatically published.
              </p>
            </>
          )}
        </div>
        <div>
          <div className="flex-vc">
            <button
              className="small-button-text small-button-text-green"
              onClick={() => {
                printData();
              }}
            >
              <div className="flex">
                <i className="mr-8">
                  <PrinterIcon className="icon-sm " />
                </i>
                <span>Print</span>
              </div>
            </button>
            <button
              className="small-button-text small-button-text-green"
              onClick={() => {
                setTimeout(function () {
                  generateAndSavePDF();
                }, 5000);
              }}
            >
              <div className="flex">
                <i style={{ color: '#4cb944' }} className="mr-8">
                  <DownloadIcon className="icon-sm" fill="#3c9436" />
                </i>
                <span>Download</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="schedule-scrollbar-grid-table-wrap">
        <ul className="schedule-grid-table-2-500 schedule-gridtable">
          <li>Time</li>
          <li>Sessions</li>

          {splicedList.length > 0 &&
            splicedList?.map((item, indx) => {
              let isRowHovered =
                isHover && rowId.toString() === item?._id.toString();
              return (
                <Fragment key={item?._id}>
                  <li
                    onMouseEnter={() => handleMouseEnter(item?._id)}
                    onMouseLeave={() => handleMouseLeave(item?._id)}
                    style={renderRowStyle(indx, item?._id, isHover, rowId)}
                  >
                    <div className="flex-vc">
                      <div>
                        <DateIcon className="icon-xxs mr-12" />
                      </div>
                      <div>
                        <p>
                          {item?.startDate && item?.endDate
                            ? areDatesSame(
                                item?.startDate,
                                item?.endDate,
                                conference?.timezone
                              )
                              ? `${getFormattedDateInTz2(
                                  item?.startDate,
                                  conference.timezone
                                )}`
                              : `${getFormattedDateInTz2(
                                  item?.startDate,
                                  conference?.timezone
                                )} ${getFormattedTimeOnyInTz(
                                  item?.startDate,
                                  conference?.timezone
                                )} - ${getFormattedDateInTz2(
                                  item?.endDate,
                                  conference?.timezone
                                )} ${getFormattedTimeOnyInTz(
                                  item?.endDate,
                                  conference?.timezone
                                )}`
                            : ''}
                        </p>
                        {item?.startDate &&
                          item?.endDate &&
                          areDatesSame(
                            item?.startDate,
                            item?.endDate,
                            conference?.timezone
                          ) && (
                            <p
                              style={{ whiteSpace: 'nowrap' }}
                              className="caption-2-regular-gray3"
                            >
                              {`${getFormattedTimeOnyInTz(
                                item?.startDate,
                                conference?.timezone
                              )} - ${getFormattedTimeOnyInTz(
                                item?.endDate,
                                conference?.timezone
                              )}`}
                            </p>
                          )}
                      </div>
                    </div>
                  </li>
                  <li
                    onMouseEnter={() => handleMouseEnter(item?._id)}
                    onMouseLeave={() => handleMouseLeave(item?._id)}
                    style={renderRowStyle(indx, item?._id, isHover, rowId)}
                  >
                    <div className="flex-sb">
                      <div>
                        <p
                          style={{
                            wordBreak: 'break-word',
                            flex: 1,
                          }}
                          className="avenir-heavy-18"
                        >
                          {item?.title}
                        </p>

                        <div>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-1-regular-gray3 mr-4"
                          >
                            Venue:
                          </span>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-2-regular-gray3"
                          >
                            {item?.venue}
                          </span>
                        </div>
                        <div>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-1-regular-gray3 mr-4"
                          >
                            Convenors:
                          </span>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-2-regular-gray3"
                          >
                            {item?.convenors
                              ?.map(
                                (item) =>
                                  `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
                              )
                              .join(', ')}
                          </span>
                        </div>

                        <div>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-1-regular-gray3 mr-4"
                          >
                            Speakers:
                          </span>
                          <span
                            style={{ whiteSpace: 'nowrap' }}
                            className="caption-2-regular-gray3"
                          >
                            {item?.speakers
                              ?.map(
                                (item) =>
                                  `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
                              )
                              .join(', ')}
                          </span>
                        </div>
                        {item?.resources?.length > 0 && (
                          <div className="flex-vc">
                            <div>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-1-regular-gray3 mr-4"
                              >
                                Files:
                              </span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '8px',
                              }}
                            >
                              {item?.resources?.map((resource, index) => (
                                <p
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxWidth: '150px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: '#08415c',
                                  }}
                                  className="caption-2-regular-gray3"
                                  title={resource?.fileNameWithExtension}
                                >
                                  <AddFileIcon className="icon-xs mr-4" />
                                  <span
                                    onClick={() =>
                                      openInNewTab(resource?.Location)
                                    }
                                  >
                                    {resource?.fileNameWithExtension.length > 10
                                      ? `${resource?.fileNameWithExtension.substring(
                                          0,
                                          10
                                        )}...`
                                      : resource?.fileNameWithExtension}
                                  </span>
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                        {item?.powerPointFile?.length > 0 && (
                          <div className="flex-vc">
                            <div>
                              <span
                                style={{ whiteSpace: 'nowrap' }}
                                className="caption-1-regular-gray3 mr-4"
                              >
                                PowerPoint:
                              </span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                gap: '8px',
                              }}
                            >
                              {item?.powerPointFile?.map((resource, index) => (
                                <p
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    maxWidth: '300px',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: '#08415c',
                                  }}
                                  className="caption-2-regular-gray3"
                                  title={resource?.fileNameWithExtension}
                                >
                                  <AddFileIcon className="icon-xs mr-4" />
                                  <span
                                    onClick={() =>
                                      openInNewTab(resource?.Location)
                                    }
                                  >
                                    {resource?.fileNameWithExtension.length > 45
                                      ? `${resource?.fileNameWithExtension.substring(
                                          0,
                                          45
                                        )}...`
                                      : resource?.fileNameWithExtension}
                                  </span>
                                </p>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <div
                          style={{
                            visibility: isRowHovered ? 'visible' : 'hidden',
                          }}
                          className="flex-vc"
                        >
                          <span
                            style={{
                              cursor: 'pointer',
                            }}
                            className="mr-4"
                            onClick={() => {
                              setRowData(item);
                              setShowModal(true);
                            }}
                          >
                            <EditIcon className="icon-xs" fill="#08415c" />
                          </span>
                          <span
                            style={{
                              cursor: 'pointer',
                            }}
                            className="mr-4 ml-12"
                            onClick={() => {
                              setRowData(item);
                              setSessionDeleteDialogue(true);
                            }}
                          >
                            <TranshcanIcon className="icon-xs" />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div>
                      {item?.episodes?.map((episode, index) => {
                        const isRowHovered =
                          isHover &&
                          rowId.toString() === episode.session.toString();

                        return (
                          <>
                            <div className="flex-sb ml-32 mt-12">
                              <div>
                                <p
                                  style={{
                                    flex: 1,
                                    wordBreak: 'break-word',
                                  }}
                                  className="avenir-heavy-18"
                                >
                                  {`Lecture ${index + 1}: ${episode?.title}`}
                                </p>

                                <div>
                                  <DateIcon className="icon-xxs mr-12" />
                                  <span
                                    style={{ whiteSpace: 'nowrap' }}
                                    className="caption-2-regular-gray3"
                                  >
                                    {`${getFormattedTimeOnyInTz(
                                      episode?.startDate,
                                      conference.timezone
                                    )} - ${getFormattedTimeOnyInTz(
                                      episode?.endDate,
                                      conference.timezone
                                    )}`}
                                  </span>
                                </div>

                                <div>
                                  <span
                                    style={{ whiteSpace: 'nowrap' }}
                                    className="caption-1-regular-gray3 mr-4"
                                  >
                                    Speakers:
                                  </span>
                                  <span
                                    style={{ whiteSpace: 'nowrap' }}
                                    className="caption-2-regular-gray3"
                                  >
                                    {item?.speakers
                                      ?.map(
                                        (item) =>
                                          `${item?.speaker?.firstName} ${item?.speaker?.lastName}` //
                                      )
                                      .join(', ')}
                                  </span>
                                </div>
                                {episode?.resources?.length > 0 && (
                                  <div className="flex-vc">
                                    <div>
                                      <span
                                        style={{ whiteSpace: 'nowrap' }}
                                        className="caption-1-regular-gray3 mr-4"
                                      >
                                        Files:
                                      </span>
                                    </div>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                      }}
                                    >
                                      {episode?.resources?.map(
                                        (resource, index) => (
                                          <p
                                            key={index}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              maxWidth: '150px',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              color: '#08415c',
                                            }}
                                            className="caption-2-regular-gray3"
                                            title={
                                              resource?.fileNameWithExtension
                                            }
                                          >
                                            <AddFileIcon className="icon-xs mr-4" />
                                            <span
                                              onClick={() =>
                                                openInNewTab(resource?.Location)
                                              }
                                            >
                                              {resource?.fileNameWithExtension
                                                .length > 10
                                                ? `${resource?.fileNameWithExtension.substring(
                                                    0,
                                                    10
                                                  )}...`
                                                : resource?.fileNameWithExtension}
                                            </span>
                                          </p>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                                {episode?.powerPointFile?.length > 0 && (
                                  <div className="flex-vc">
                                    <div>
                                      <span
                                        style={{ whiteSpace: 'nowrap' }}
                                        className="caption-1-regular-gray3 mr-4"
                                      >
                                        PowerPoint:
                                      </span>
                                    </div>

                                    <div
                                      style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        gap: '8px',
                                      }}
                                    >
                                      {episode?.powerPointFile?.map(
                                        (resource, index) => (
                                          <p
                                            key={index}
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              maxWidth: '350px',
                                              whiteSpace: 'nowrap',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              color: '#08415c',
                                            }}
                                            className="caption-2-regular-gray3"
                                            title={
                                              resource?.fileNameWithExtension
                                            }
                                          >
                                            <AddFileIcon className="icon-xs mr-4" />
                                            <span
                                              onClick={() =>
                                                openInNewTab(resource?.Location)
                                              }
                                            >
                                              {resource?.fileNameWithExtension
                                                .length > 50
                                                ? `${resource?.fileNameWithExtension.substring(
                                                    0,
                                                    45
                                                  )}...`
                                                : resource?.fileNameWithExtension}
                                            </span>
                                          </p>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                              <div>
                                <div className="flex-vc">
                                  <i
                                    style={{
                                      cursor: 'pointer',
                                      visibility: 'visible',
                                      visibility: isRowHovered
                                        ? 'visible'
                                        : 'hidden',
                                    }}
                                    className="mr-4"
                                    onClick={() => {
                                      setRowData(episode);
                                      setSession(item);
                                      setShowLectureForm(true);
                                    }}
                                  >
                                    <EditIcon
                                      className="icon-xs"
                                      fill="#08415c"
                                    />
                                  </i>
                                  <i
                                    style={{
                                      cursor: 'pointer',
                                      visibility: isRowHovered
                                        ? 'visible'
                                        : 'hidden',
                                    }}
                                    className="mr-4 ml-12"
                                    onClick={() => {
                                      setRowData(episode);
                                      setSession(item);
                                      setLectureDeleteDialogue(true);
                                    }}
                                  >
                                    <TranshcanIcon className="icon-xs" />
                                  </i>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                      <div className="ml-32 mt-16 mb-16">
                        <button
                          onClick={() => {
                            setSession(item);
                            setShowLectureForm(true);
                          }}
                          className="small-button-outlined small-button-outlined-primary"
                        >
                          + Add Lecture
                        </button>
                      </div>
                    </div>
                  </li>
                </Fragment>
              );
            })}
        </ul>
        {showModal && (
          <ModalX onDismiss={() => setShowModal(false)} open={showModal}>
            <div className="modalx-container-md pl-16 pr-16 pb-24 pt-32">
              <AddScheduleForm
                setShowModal={setShowModal}
                page={activePage}
                setCount={setTotalRecords}
                filter={setFilter1}
                searchText={searchText}
                conference={conference}
                setSessions={setSessions}
                rowData={rowData}
                setRowData={setRowData}
              />
            </div>
          </ModalX>
        )}

        {showLectureForm && (
          <ModalX
            onDismiss={() => setShowLectureForm(false)}
            open={showLectureForm}
          >
            <div className="modalx-container-md pl-16 pr-16 pb-24 pt-32">
              <AddLectureForm
                editMode={false}
                setShowModal={setShowModal}
                page={activePage}
                setCount={setTotalRecords}
                filter={setFilter1}
                searchText={searchText}
                conference={conference}
                setSessions={setSessions}
                session={session}
                setShowLectureForm={setShowLectureForm}
                rowData={rowData}
                setRowData={setRowData}
              />
            </div>
          </ModalX>
        )}
      </div>
      {sessionDeleteDialogue && (
        <DialogueWithLoader
          msg="Are you sure you want to delete this session?"
          title="Delete session"
          closeDialogue={() => {
            setSessionDeleteDialogue(false);
          }}
          previewLoading={isDialogueLoading}
          yesAction={() => deleteData(`/session/${rowData?._id}`)}
        />
      )}
      {lectureDeleteDialogue && (
        <DialogueWithLoader
          msg="Are you sure you want to delete this lecture?"
          title="Delete lecture"
          closeDialogue={() => {
            setLectureDeleteDialogue(false);
          }}
          previewLoading={isDialogueLoading}
          yesAction={() =>
            deleteData(`/session/${session?._id}/episodes/${rowData?._id}`)
          }
        />
      )}
      <div className="flex-vc-sb mt-8">
        <div>
          <CustomPagination
            currentPageNumber={activePage}
            setCurrentPage={setActivePage}
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={1}
            onPageChanged={(data) => loadPaginationData(data)}
          />
        </div>
        <div>
          <PageLimitSelect
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            activePage={activePage}
            totalRecords={totalRecords}
            loadPaginationData={loadPaginationData}
          />
        </div>
        <div style={{ display: 'none' }}>
          <div ref={componentRef} className="print-margins">
            {<PrintSchedule sessions={sessions} conference={conference} />}
          </div>
        </div>
      </div>
    </>
  );
}
