const confemyWhite = '#ffffff';
const confemyBlac = '#000000';
const shade1 = '#ced9de';
const shade2 = '#ecf0f2';
const shade3 = '#fcfdfd';
const shade4 = '#aabdc7';

// SelectFormType1 is form customized according to our form-type-1 design
export const formType1Styles = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: confemyWhite,
      border: state.isFocused ? '1px solid #55a0fa' : `2px solid ${shade1}`,
      padding: '13px 0px 13px 16px',
      fontFamily: 'Avenir-Roman',
      fontSize: 16,

      ':hover': {
        border: state.isFocused ? '1px solid #55a0fa' : `solid 2px ${shade4}`,
      },
    };
  },

  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),

  placeholder: (provided) => ({
    ...provided,
    // fontSize: "1em",
    // color: confemyBlac,
    // fontWeight: 400,
  }),

  option: (provided, state) => {
    return {
      ...provided,
      color: confemyBlac,
      backgroundColor: state.isSelected ? shade2 : '#fff',
      fontSize: 16,
      fontFamily: 'Avenir-Roman',
      padding: 16,
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: shade1,
      ':hover': {
        color: shade4,
      },
    };
  },

  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      backgroundColor: shade3,
      color: confemyBlac,
      fontSize: 16,
    };
  },
};

export const formType3Styles = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: confemyWhite,
      border: state.isFocused ? '1px solid #55a0fa' : `2px solid ${shade1}`,
      // padding: "13px 0px 13px 16px",
      padding: '4px 0px 4px 16px',
      fontFamily: 'Avenir-Roman',
      fontSize: 16,

      ':hover': {
        border: state.isFocused ? '1px solid #55a0fa' : `solid 2px ${shade4}`,
      },
    };
  },

  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),

  placeholder: (provided) => ({
    ...provided,
    // fontSize: "1em",
    // color: confemyBlac,
    // fontWeight: 400,
  }),

  // this is for options list
  menuList: (base) => ({
    ...base,
    position: 'fixed !important',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.08)',
    width: '20rem',
  }),

  option: (provided, state) => {
    return {
      ...provided,
      color: confemyBlac,
      backgroundColor: state.isSelected ? shade1 : '#fff',
      fontSize: 16,
      fontFamily: 'Avenir-Roman',
      padding: 8,
      ':hover': {
        backgroundColor: shade2,
      },
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: shade1,
      ':hover': {
        color: shade4,
      },
    };
  },

  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      backgroundColor: shade3,
      color: confemyBlac,
      fontSize: 16,
    };
  },
};

export const customSelectStyle3 = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: confemyWhite,
      border: state.isFocused ? '1px solid #55a0fa' : `2px solid ${shade1}`,
      // padding: "13px 0px 13px 16px",
      padding: '4px 0px 4px 16px',
      fontFamily: 'Avenir-Roman',
      fontSize: 16,
      minHeight: '48px',

      ':hover': {
        border: state.isFocused ? '1px solid #55a0fa' : `solid 2px ${shade4}`,
      },
    };
  },

  // placeholder: (provided) => ({
  //   ...provided,
  //   // fontSize: "1em",
  //   // color: confemyBlac,
  //   // fontWeight: 400,
  // }),

  // this is for options list
  menuList: (base) => ({
    ...base,
    //   position: 'fixed !important',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.08)',

    //   width: '20rem',
  }),

  option: (provided, state) => {
    return {
      ...provided,
      color: confemyBlac,
      backgroundColor: state.isSelected ? shade1 : '#fff',
      fontSize: 16,
      fontFamily: 'Avenir-Roman',
      padding: 8,
      ':hover': {
        backgroundColor: shade2,
      },
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: shade1,
      ':hover': {
        color: shade4,
      },
    };
  },

  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      backgroundColor: shade3,
      color: confemyBlac,
      fontSize: 16,
    };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
};

export const customSelectStyleSchedule = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: confemyWhite,
      border: state.isFocused ? '1px solid #55a0fa' : `2px solid ${shade1}`,
      // padding: "13px 0px 13px 16px",
      padding: '4px 0px 4px 16px',
      fontFamily: 'Avenir-Roman',
      fontSize: 16,
      minHeight: '58px',

      ':hover': {
        border: state.isFocused ? '1px solid #55a0fa' : `solid 2px ${shade4}`,
      },
    };
  },

  // placeholder: (provided) => ({
  //   ...provided,
  //   // fontSize: "1em",
  //   // color: confemyBlac,
  //   // fontWeight: 400,
  // }),

  // this is for options list
  menuList: (base) => ({
    ...base,
    //   position: 'fixed !important',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.08)',

    //   width: '20rem',
  }),

  option: (provided, state) => {
    return {
      ...provided,
      color: confemyBlac,
      backgroundColor: state.isSelected ? shade1 : '#fff',
      fontSize: 16,
      fontFamily: 'Avenir-Roman',
      padding: 8,
      ':hover': {
        backgroundColor: shade2,
      },
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: shade1,
      ':hover': {
        color: shade4,
      },
    };
  },

  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      backgroundColor: shade3,
      color: confemyBlac,
      fontSize: 16,
    };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
};

export const customSelectStyleSmallButton = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: confemyWhite,
      border: state.isFocused ? '1px solid #55a0fa' : `2px solid ${shade1}`,
      // padding: "13px 0px 13px 16px",
      padding: '8px 0px 8px 16px',
      fontFamily: 'Avenir-Roman',
      fontSize: 16,

      ':hover': {
        border: state.isFocused ? '1px solid #55a0fa' : `solid 2px ${shade4}`,
      },
    };
  },

  // placeholder: (provided) => ({
  //   ...provided,
  //   // fontSize: "1em",
  //   // color: confemyBlac,
  //   // fontWeight: 400,
  // }),

  // this is for options list
  menuList: (base) => ({
    ...base,
    //   position: 'fixed !important',
    backgroundColor: 'white',
    borderRadius: '4px',
    boxShadow: '0 8px 32px 0 rgba(0, 0, 0, 0.08)',
    //   width: '20rem',
  }),

  option: (provided, state) => {
    return {
      ...provided,
      color: confemyBlac,
      backgroundColor: state.isSelected ? shade1 : '#fff',
      fontSize: 16,
      fontFamily: 'Avenir-Roman',
      padding: 8,
      ':hover': {
        backgroundColor: shade2,
      },
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: shade1,
      ':hover': {
        color: shade4,
      },
    };
  },

  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      backgroundColor: shade3,
      color: confemyBlac,
      fontSize: 16,
    };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
};

//
export const modalStyleformType1 = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: confemyWhite,
      border: state.isFocused ? '1px solid #55a0fa' : `2px solid ${shade1}`,
      padding: '13px 0px 13px 16px',
      fontFamily: 'Avenir-Roman',
      fontSize: 16,

      ':hover': {
        border: state.isFocused ? '1px solid #55a0fa' : `solid 3px ${shade4}`,
      },
    };
  },

  menuPortal: (base) => ({ ...base, zIndex: 9999 }),

  placeholder: (provided) => ({
    ...provided,
    // fontSize: "1em",
    // color: confemyBlac,
    // fontWeight: 400,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),

  option: (provided, state) => {
    return {
      ...provided,
      color: confemyBlac,
      backgroundColor: state.isSelected ? shade2 : '#fff',
      fontSize: 16,
      fontFamily: 'Avenir-Roman',
      padding: 16,
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: shade1,
      ':hover': {
        color: shade4,
      },
    };
  },

  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
      // margin: "0 4px",
      height: 52,
      backgroundColor: confemyWhite,
      fontSize: 18,
    };
  },
};

export const modalStyleformType4 = {
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: confemyWhite,
      border: state.isFocused ? '1px solid #55a0fa' : `2px solid ${shade1}`,
      padding: '14px 0px 14px 16px',
      fontFamily: 'Avenir-Roman',
      fontSize: 16,
      ':hover': {
        border: state.isFocused ? '1px solid #55a0fa' : `solid 2px ${shade4}`,
      },
    };
  },

  menuPortal: (base) => ({ ...base, zIndex: 9999 }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: '1.6rem',
    color: '#757575',
    // color: confemyBlac,
    // fontWeight: 400,
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: 'ellipsis',
    width: '160px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),

  option: (provided, state) => {
    return {
      ...provided,
      color: confemyBlac,
      backgroundColor: state.isSelected ? shade2 : '#fff',
      fontSize: 16,
      fontFamily: 'Avenir-Roman',
      padding: 16,
    };
  },

  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: shade1,
      ':hover': {
        color: shade4,
      },
    };
  },

  noOptionsMessage: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
      // margin: "0 4px",
      height: 52,
      backgroundColor: confemyWhite,
      fontSize: 18,
    };
  },
};
